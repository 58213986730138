import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import useAuth from "../hooks/useAuth.js";
import { useState, useEffect } from "react";
import Components from "../hooks/components.js";
import { Row,  Card } from 'react-bootstrap';
import { overviewElements } from '../config/overviewElements';

const MoldOverview = ({listedMolds, fullDisplayMode}) => {   
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [activeElementsLess, setActiveElementsLess] = useState([]);
    const [activeElementsFull, setActiveElementsFull] = useState([]);

    useEffect(() => {
        const getSettings = async () => {
            try {
                const response = await axiosPrivate.get('/users/' + auth.userInfo.id, {              
                });
                
                setActiveElementsLess(response.data.overview_settings_less !== null ? clearWidgetsAndUpdate(response.data.overview_settings_less, overviewElements) : []);
                setActiveElementsFull(response.data.overview_settings_more !== null ? clearWidgetsAndUpdate(response.data.overview_settings_more, overviewElements) : []);

            } catch (error) {
                console.log(error);
            }
        }

        getSettings();

        return () => {
        }
    }, [])

    let arr;

    const clearWidgetsAndUpdate = (mainArray, controlArray) => { // abgleich mit widgetliste
        const controlMap  = new Map(controlArray.map(item => [item.id, item]));
       
        // return mainArray.filter(item => controlIDs .has(item.id));  // Filtert array1 und entfernt Objekte, die ID nicht( ID) in array2 enthalten ist
        return mainArray
        .filter(item => controlMap.has(item.id)) // Behalte nur IDs, die im Kontrollarray existieren
        // .map(item => controlMap.get(item.id));  
        .map(item => {
            const controlData = controlMap.get(item.id); // Daten aus dem Kontrollarray
            return {
              ...controlData,         // Werte aus dem Kontrollarray (überschreibt die allgemeinen Daten)
              row: item.row,          // row aus mainArray übernehmen
              col: item.col           // col aus mainArray übernehmen
            };
        });
    }

    const sortObjectsByPosition = (array) =>{

        return array.sort((a, b) => {
       
          if (a.row !== b.row) {   // nach row sortieren
            return a.row - b.row;
          }
          return a.col - b.col; // Wenn row gleich, nach cell sortieren
        });
      }
      
      arr = sortObjectsByPosition(fullDisplayMode ? activeElementsFull : activeElementsLess);
  
    return (
    <>   
    <div className="moldoverview my-3" id="moldOverviewLess">
    {
        listedMolds.map((moldId, index) => 
            <Card className="mb-3" key={index}>
                <Row>
                    {arr.map((Element, subindex) => { // Element = activeElementsFull/-Less = OverviewElement
            
                        return (
            
                                <div className={`area col-${Element.sizeX}`}  key={subindex}>
                                    {Components(Element, moldId )}
                                </div>
                           
                        )
                    })
                }
                  
                </Row>
            </Card>
        )               
    }   

       
    </div>
   

   </>
    
    );
}
export default MoldOverview;

