
import { useState,useEffect } from "react";
import { Card, Container } from 'react-bootstrap';
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useTranslation } from 'react-i18next';


import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import MoldOverview from '../components/MoldOverview';


const Overview = () => {
    let switchRef;
    let initialState = (localStorage.getItem("fullDisplayMode") === null ? false : JSON.parse(localStorage.getItem("fullDisplayMode") ));

    const { t } = useTranslation();
    const [fullDisplayMode, setFullDisplayMode] = useState(initialState);

    function change() {
        setFullDisplayMode(!fullDisplayMode);
    }


    useEffect(() => {
      localStorage.setItem('fullDisplayMode', fullDisplayMode);
    }, [fullDisplayMode]);

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [listedMolds, setListedMolds] = useState([0]);

    useEffect(() => {
        const getMolds = async () => {
            try {
                const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
                });
                let molds = response.data.molds.filter((item) => { return item.active === true; });
                setListedMolds(molds);
            } catch (error) {
                console.log(error);
            }

            
        }
        getMolds();
        return () => {
        }
    }, [])

  
    return (
        <Container className="pt-5 mt-5 generalpage">         
            <Card>
                <Card.Title> {t('overview')} 
                    <div className="float-end">
                        <label htmlFor="switch" className="pe-2"> {t('moreInfo')}  </label>
                        <SwitchComponent id="switch" ref={(scope) => { switchRef = scope; }} value="MoreInfo" change={change} checked={fullDisplayMode} />
                        </div>
                </Card.Title>
            </Card>          
           <MoldOverview listedMolds={listedMolds} fullDisplayMode={fullDisplayMode}/>
       
        </Container>
    )
}

export default Overview
